import React from "react"
import { Link } from "react-router-dom"

function Intervals(){
    return (
        <div id="intervalRecognition">
            <Link>
                <h1 id="comingsoon">coming soon</h1>
            </Link>
        </div>
        
    )
}

export default Intervals